import { Link } from "react-router-dom"

export const getInternalLink = ({ link, text }) => (
  <Link to={link} className="internal-link">
    {text}
  </Link>
)

export const getPatientProgramDetailsPageLink = ({ text, patientId, programId, currentSubdomain }) => {
  const link = `/${currentSubdomain}/patient/view-program/${patientId}/${programId}`
  return getInternalLink({ link, text })
}

export const getPatientProfilePageLink = ({ text, patientId, currentSubdomain }) => {
  const link = `/${currentSubdomain}/patient/view/${patientId}`
  return getInternalLink({ link, text })
}

export const getDefaultDataTableRenderFn = (value) => value
