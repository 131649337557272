import { useSelector } from "react-redux"
import { formatDate, getCurrentSubdomain } from "../../../utils/common"
import { PresignedUrlFileIcon } from "../PresignedUrlFileIcon"
import {
  getDefaultDataTableRenderFn,
  getPatientProfilePageLink,
  getPatientProgramDetailsPageLink,
} from "./constants"

const getFileIcon = ({ value }) => value && <PresignedUrlFileIcon documentId={value} showLoader />

const getFileList = ({ fileList = [] }) =>
  Array.isArray(fileList) && fileList?.length > 0 && fileList?.map((value) => getFileIcon({ value }))

export const useColumnsFormatting = ({ customDefaultRenderFn = null } = {}) => {
  const currentSubdomain = useSelector(getCurrentSubdomain)
  const getRenderFunction = ({ column = {} } = {}) => {
    const { renderFn = "default" } = column
    const renderFnMap = {
      default: customDefaultRenderFn || getDefaultDataTableRenderFn,
      date: (value) => formatDate(value),
      file: (value) => getFileIcon({ value }),
      fileList: (fileList) => getFileList({ fileList }),
      booleanToYesNo: (value) => (value ? "Yes" : "No"),
      linkToPatientProgramDetailsPage: (value, record) => {
        const patientId = record?.patient_id || record?.id || record?.uid || ""
        const programId = record?.program_id || ""
        return getPatientProgramDetailsPageLink({ currentSubdomain, patientId, programId, text: value })
      },
      linkToPatientProfilePage: (value, record) => {
        const patientId = record?.patient_id || record?.id || record?.uid || ""
        return getPatientProfilePageLink({ currentSubdomain, patientId, text: value })
      },
    }
    const renderFunction = renderFnMap[renderFn] || renderFnMap.default
    return renderFunction
  }
  const getFormattedColumn = ({ column = {} } = {}) => {
    const renderFunction = getRenderFunction({ column })
    const formattedColumn = {
      ...column,
      render: renderFunction,
    }
    return formattedColumn
  }
  const getFormattedColumns = ({ columns = [] } = {}) => {
    const formattedColumns = columns.map((column) => getFormattedColumn({ column }))
    return formattedColumns
  }
  return [getFormattedColumns]
}
